import React from 'react';

import ProductSvgSection from './product_svg_section';
import ProductNavSection from './product_nav_section';
import ProductSection from './product_section';
import FooterSection from './footer_section';
import ProductTopSection from './product_top_section';
import HeroSection from './hero';
import Features from './features';
import HeroLeft from './hero_left';
import Header from './header';
import Contact from '../contact';

const Container = () => {


    return (
        <>
            {/* <ProductTopSection/> */}
            
            <Header/>
            {/* <ProductNavSection/> */}
            <HeroSection/>
            <Features/>
            <HeroLeft/>
            {/* <Pricing/> */}
            <Contact/>
            <div id="review-carousel" data-customer-sid="CS00005"></div>
            <div id="postReview" data-customer-sid="CS00005" data-widget-name="review_post"></div> 
            <div id="rating" data-customer-sid="CS00005" data-widget-name="company_rating"></div>
            <FooterSection/>
        </>
    )
}
export default Container;