import React, { useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Grid, Button } from '@mui/material';
import S3Uploader from './s3Uploader';
import { v4 as uuidv4 } from 'uuid';
import { addCandidate } from '../api/agentApi';
import { agentDashboard } from "../utils/navigateUrls";
import AutoHideAlert from './autoHideAlert';
import { trackEvent, eventCategories } from '../constants/analytics';

const AddCandidate = () => {
    const { agentId: agent_id } = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberErrorText, setPhoneNumberErrorText] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadResponse, setUploadResponses] = useState(null);
    const uniqId = uuidv4(); 
    const [uploadFileName, setUploadFileName] = useState(uniqId);
    const bucket = 'jp-cand-resume';
    const navigate = useNavigate();
    const [candidateAddedAlert, setCandidateAddedAlert] = useState(false);

    // console.log(`nodeenv: ${process.env.NODE_ENV}`);

    const onNameChange = (e) => {
        const value = e?.target?.value;
        setName(value);
        validateName(value);
    };
    const validateName = (value) => {
        if(value.length==0){
            setNameErrorText("Name should not be empty")
            setNameError(true);
        }else{
            setNameErrorText("")
            setNameError(false);
        }
    }
    const validateWhatsAppNumber = (value) => {
        if(value.length==0){
            setPhoneNumberError(true);
            setPhoneNumberErrorText("Whatsapp number should not be empty");
        } else if(value.length>10){
            setPhoneNumberError(true);
            setPhoneNumberErrorText("Whatsapp number should be 10 digits");
        } else {
            setPhoneNumberError(false);
            setPhoneNumberErrorText("");
        }
        
    }
    const onNameBlur = (e) => {
        const value = e?.target?.value;
        validateName(value);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e?.target?.value;
        var mobileNumber = value.replace(/^\+91\s*/, '').replace(/\s+/g, '');
        setPhoneNumber(mobileNumber);
        validateWhatsAppNumber(mobileNumber);
    };

    const onPhoneNumberBlur = (e) => {
        const value = e?.target?.value;
        validateWhatsAppNumber(value);
        // Handle phone number blur logic
    };

    const onFileChange = (file) => {
        const extension = file.name.split('.')[1];
        const fileName = `${uniqId}.${extension}`;
        setUploadFileName(fileName)
        setUploadFile(file);
    };

    const onFileUpload = (response) => {
        trackEvent(eventCategories.candidateActions, 'Upload Resume', 'Candidate Resume uploaded');
        setUploadResponses(response);
    };

    const handleSubmit = async () => {
        // Handle form submission logic
        const response = await addCandidate({
            agent_id,
            name,
            phone_number: phoneNumber,
            metadata: {
                bucket: bucket,
                key: uploadFileName
            }
        });
        trackEvent(eventCategories.candidateActions, 'Submit Resume', 'Candidate Resume submitted');
        setCandidateAddedAlert(true);
        setTimeout(() => {
            navigate(agentDashboard({agent_id}));    
        }, 1500);
        
    };

    const isSubmitDisabled = () => {
        return phoneNumberError || nameError || uploadResponse?.status != 'success';
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setCandidateAddedAlert(false);
    };

    return (
        <Grid
            container
            justifyContent="center"
            sx={{ padding: '16px', maxWidth: '400px', margin: 'auto' }}
            spacing={2}
        >
            <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={onNameChange}
                    fullWidth
                    placeholder='Enter Candidate Name'
                    error={nameError}
                    helperText={nameErrorText}
                    onBlur={onNameBlur}
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <TextField
                    label="Whatsapp Number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                    placeholder='Enter candidate 10 digit whatsapp number'
                    error={phoneNumberError}
                    helperText={phoneNumberErrorText}
                    onBlur={onPhoneNumberBlur}
                />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <S3Uploader
                    uploadText="Upload PDF Resume"
                    onFileChange={onFileChange}
                    onFileUpload={onFileUpload}
                    bucket={bucket}
                    s3FileKey={uploadFileName}
                />
            </Grid>
            <Grid item xs={12}>
                <Button 
                    onClick={handleSubmit} 
                    disabled={ isSubmitDisabled() } 
                    fullWidth
                    variant='contained'
                    >
                    Add Candidate
                </Button>
            </Grid>
            { candidateAddedAlert ? 
            <AutoHideAlert 
                message="Candidate added successfully, redirecting to dashboard"
                open={candidateAddedAlert}
                handleClose={handleClose}
                timeout={3000}
            />
                : null
            }
        </Grid>
    )
}

export default AddCandidate;
